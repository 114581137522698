.pc-games-container {
    background-color: #111;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 2rem; /* Adds spacing at the bottom */
}

/* Hero Section */
.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh; /* Adjusted for a slightly taller hero section */
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 2rem;
    position: relative;
    color: #fff;
}

.hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
    z-index: 1;
}

.hero-section h1 {
    font-size: 3.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    z-index: 2; /* Ensures text is above the overlay */
    color: #fff;
}

.hero-section p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
    color: #fff;
}

.whitepaper-button {
    color: #fff;
    background-color: #007bff;
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s;
    position: relative;
    z-index: 2;
    font-weight: bold;
}

.whitepaper-button:hover {
    background-color: #0056b3;
}

/* Video Section */
.video-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    background-color: #222;
    border-radius: 8px;
    margin: 2rem auto;
    width: 90%;
    max-width: 900px;
}

.video-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #fff;
}

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
    border-radius: 10px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(1.2); /* Adjust scale as needed */
    width: 200%; /* Slightly larger width to cover black areas */
    height: 100%;
    border: none;
}

/* Community Section */
.community-section {
    background-color: #1a1a1a; /* Dark background with slight contrast */
    padding: 3rem;
    text-align: center;
    color: #e0e0e0; /* Light text color for readability */
    border-radius: 8px; /* Add subtle rounded corners */
    margin-top: 2rem;
}

.community-section .community-description {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    color: #cccccc;
}

.community-section .community-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #ffffff;
}

.icon-container {
    display: flex;
    justify-content: center;
    gap: 2rem; /* Space out the icons */
    margin-top: 1.5rem;
}

.community-icon {
    font-size: 2.5rem;
    color: #ffffff;
    transition: transform 0.3s, color 0.3s;
}

.community-icon:hover {
    color: #007bff; /* Change color on hover for visual effect */
    transform: scale(1.2); /* Slightly enlarge icon on hover */
}
