.terms-of-use-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.terms-of-use-container h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.terms-of-use-container p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.terms-of-use-container p strong {
  font-size: 1.2rem;
  font-weight: bold;
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: #444;
}
