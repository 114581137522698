/* Home.css */

.home-container {
  position: relative;
  text-align: center;
}

/* Video Section */
.video-container {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust height as needed */
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.cta-overlay {
  position: relative;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 8px;
}

.cta-title {
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.cta-subtitle {
  font-size: 1.5rem;
  color: #dcdcdc;
  margin-bottom: 1.5rem;
}

.cta-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s;
}

.cta-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* About Section */
.about-section {
  text-align: center;
  padding: 2rem 1rem;
  background-color: #f8f9fa;
}

.about-title {
  font-size: 2rem;
  color: #444;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  color: #555;
}

.about-subtitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 1.5rem;
  color: #333;
}

.about-content p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0.5rem 0;
  color: #666;
}

/* Services Section */
.services-section {
  text-align: center;
  padding: 2rem 1rem;
  background-color: #f1f1f1;
}

.services-title {
  font-size: 2rem;
  color: #444;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.services-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 200px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 0.5rem;
}

.service-card h3 {
  font-size: 1.25rem;
  color: #333;
  margin: 0;
}

/* News Section */
.news-section {
  text-align: center;
  padding: 3rem 1rem;
  background-color: #f8f9fa;
}

.news-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  font-weight: bold;
}

.news-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.news-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.news-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.news-card-title {
  font-size: 1.5rem;
  color: #333;
  padding: 1rem;
  font-weight: bold;
  text-align: left; /* Align text to the left */
}


.news-card-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  padding: 0 1rem;
  text-align: justify; /* Aligns the text to both left and right */
  margin-bottom: 1.5rem;
  overflow: visible; /* Ensure no overflow */
  white-space: normal; /* Allow text to wrap */
}


.news-card-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
  margin: 0 1rem 1.5rem;
}

.news-card-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .news-cards {
    grid-template-columns: 1fr;
  }

  .news-image {
    height: 180px;
  }

  .news-card-title {
    font-size: 1.3rem;
  }

  .news-card-description {
    font-size: 0.95rem;
  }

  .news-card-button {
    font-size: 0.9rem;
  }
}

/* News Modal Styling */
.news-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh; /* Limit height to 80% of the viewport */
  overflow-y: auto; /* Enable scrolling */
  position: relative;
  outline: none;
}

.news-modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.news-modal-image {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.news-modal-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

/* Close button adjustments for better accessibility */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #888;
}

.news-modal-full-description {
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
  margin-top: 1rem;
  text-align: left;
  padding-right: 1rem; /* Add padding for better spacing */
  overflow-wrap: break-word; /* Prevent text overflow */
}

.modal-paragraph {
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
  margin: 1rem 0;
  text-align: left;
}

.modal-subtitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: #555;
  margin: 1.5rem 0 0.5rem;
  text-align: left;
}


/* Subscribe Section Styling */
.subscribe-section {
  text-align: center;
  margin: 2rem auto;
  padding: 1rem;
}

.subscribe-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  font-weight: bold;
}

.subscribe-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.subscribe-input {
  width: 300px; /* Adjust width as needed */
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.subscribe-button {
  padding: 0.5rem 1.5rem;
  border: 1px solid #333;
  background-color: transparent;
  color: #333;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #333;
  color: #fff;
}
