.footer {
  background-color: #333;
  color: #ccc;
  text-align: center;
  padding: 20px;
  font-size: 0.9rem;
}

.footer-top {
  margin-bottom: 20px;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.footer-links a {
  color: #ccc;
  text-decoration: none;
}

.footer-links a:hover {
  color: #fff;
}

.footer-social {
  display: flex;
  justify-content: center;
  gap: 15px;
  font-size: 1.5rem;
  margin: 15px 0;
}

.footer-info {
  font-weight: bold;
  margin: 10px 0;
}

.footer-address, .footer-phone {
  color: #aaa;
  margin: 5px 0;
}

.footer-phone {
  text-decoration: none;
  color: #00aaff;
}

.footer-phone:hover {
  color: #005f99;
}

.footer-bottom {
  margin-top: 15px;
  font-size: 0.8rem;
  color: #aaa;
}
