.mobile-games-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 1200px; /* Increase the maximum width */
  margin: 0 auto;
}

.game-card {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: 1.5rem;
  margin: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 100%; /* Make game card take the full container width */
  width: 100%; /* Ensures it stretches to fill the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.game-info-section {
  flex: 1;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.game-icon {
  width: 125px;
  height: 125px;
  border-radius: 8px;
  margin: 1rem 0;
}

.game-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.game-subtitle {
  font-size: 1rem;
  color: #666;
  margin-top: 0.25rem;
}

.game-description {
  font-size: 1rem;
  color: #444;
  margin: 1rem 0;
}

.game-details {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: #666;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.rating, .downloads, .teacher-approved, .age-rating {
  display: flex;
  align-items: center;
}

.star-icon, .teacher-icon {
  color: #ffc107;
  margin-right: 0.25rem;
}

.download-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  width: 150px;
}

.google-play {
  background-color: #34a853;
  color: #fff;
}

.apple-store {
  background-color: #000;
  color: #fff;
}

.google-play:hover {
  background-color: #2a8537;
}

.apple-store:hover {
  background-color: #333;
}

.game-video-section {
  width: 500px; /* Adjusted width for a wider video */
  height: 280px; /* Adjust height proportionally */
  border-radius: 8px;
  overflow: hidden;
}

.game-video-section iframe {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.store-icon {
  width: 150%;
  height: auto;
}