/* Contact.css */

.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Open Sans', sans-serif;
    color: #333;
    text-align: center;
}

.contact-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #444;
}

.contact-details {
    margin-bottom: 2rem;
    line-height: 1.6;
}

.contact-details p {
    font-size: 1rem;
    color: #555;
}

.contact-details h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1rem;
    color: #333;
}

.map-container {
    margin-top: 2rem;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-container iframe {
    width: 100%;
    height: 400px;
    border: 0;
    border-radius: 8px;
}
