/* Container Styling */
.faaliyet-raporlari-container {
    padding: 2rem;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #333;
}

/* Section Titles */
.section-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 0.5rem;
}

.section-subtitle {
    font-size: 1.2rem;
    color: #888;
    margin-bottom: 1.5rem;
    font-style: italic;
}

/* Report List Styling */
.report-list {
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto 2rem;
    padding: 1rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Individual Report Items */
.report-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
}

.report-item:last-child {
    border-bottom: none; /* Remove the bottom border for the last item */
}

/* Download Link Styling */
.download-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    display: flex;
    align-items: center;
    transition: color 0.3s;
}

.download-link:hover {
    color: #0056b3;
}

/* Download Icon */
.download-link svg {
    margin-right: 0.25rem;
}

/* Communication Form Styling */
.communication-form-container {
    padding: 2rem;
    text-align: center;
}

.communication-form {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #0056b3;
}

.section-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
}

.section-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1.5rem;
}
