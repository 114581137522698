/* src/styles.css */

/* Reset some default styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Header Styling */
.navbar {
  background-color: #343a40; /* Dark gray background */
  padding: 0.5rem 2rem; /* Adjust padding for a sleeker look */
}

.navbar .container {
  max-width: 1200px; /* Limit the width of the header content */
  margin: 0 auto; /* Center the header content */
}

.navbar-brand {
  font-weight: bold;
  color: #f8f9fa; /* Light color for brand text */
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  padding-right: 1rem; /* Space between logo and first menu item */
}

.logo-animated {
  margin-right: 10px; /* Add space between the logo and brand text if needed */
}

.navbar-nav .nav-link {
  color: #f8f9fa; /* Light text for links */
  margin-right: 1.5rem;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #007bff; /* Blue on hover */
}

.navbar-nav .active {
  border-bottom: 2px solid #007bff; /* Indicator for active page */
}



/* Footer Styling */
footer {
  background-color: #343a40;
  color: #f8f9fa;
  padding: 1rem 2rem;
  text-align: center;
  font-size: 0.9rem;
}

footer a {
  color: #007bff;
  margin: 0 0.5rem;
  font-weight: 500;
}

footer a:hover {
  color: #f8f9fa;
}

footer span {
  display: block;
  margin-top: 0.5rem;
}



/* Page Content Styling */
.page-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  color: #343a40;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}



/* Button Styling */
.btn-primary {
  background-color: #007bff;
  border: none;
  color: #ffffff;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}


/* Hide all logos by default */
.logo-small,
.logo-medium,
.logo-large,
.logo-xlarge {
  display: none;
}

/* Display specific logo based on screen size */
@media (max-width: 576px) {
  .logo-small {
    display: inline;
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .logo-medium {
    display: inline;
    width: 76px;
    height: 76px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .logo-large {
    display: inline;
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 993px) {
  .logo-xlarge {
    display: inline;
    width: 125px;
    height: 125px;
  }
}
/* Align the nav items to the right */
.navbar-nav-right {
  margin-left: auto;
  
  
}



/* Keyframes for rotating the logo three times quickly, then pausing */
@keyframes rotate-logo-fast {
  0% {
    transform: rotate(360deg);
  }
   {
    transform: rotate(0deg); /* First rotation */
  }
}

/* Apply the animation to the logo */
.logo-animated {
  animation: rotate-logo-fast 6s ease-in-out infinite; /* 2.5s total duration, infinite loop */
}

