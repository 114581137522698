/* Genel ayarlar */
.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

/* Chatbot simgesi */
.chatbot-icon {
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

.chatbot-icon:hover {
    background-color: #0056b3;
}

/* Chatbot penceresi */
.chatbot-window {
    width: 300px;
    height: 400px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 80px;
    right: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: Arial, sans-serif;
}

.chatbot-header {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatbot-header h4 {
    margin: 0;
    font-size: 1.1rem;
}

.chatbot-header button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
}

.chatbot-body {
    padding: 10px;
    flex: 1;
    overflow-y: auto;
}

.chatbot-footer {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.chatbot-footer input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 5px;
}

.chatbot-footer button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.chatbot-footer button:hover {
    background-color: #0056b3;
}

/* Mesaj balonları */
.user-message {
    background-color: #007bff;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 5px;
    align-self: flex-end;
    max-width: 80%;
}

.bot-message {
    background-color: #e5e5e5;
    color: #333;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 5px;
    align-self: flex-start;
    max-width: 80%;
}
